import React, { useContext, useState, useEffect } from 'react';
import { View, FlatList, Text, Image } from 'react-native';
import { Divider, List } from 'react-native-paper';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header, Icon } from 'react-native-elements';
import { TouchableHighlight } from 'react-native-gesture-handler';
/* Components */
import Loading from '../components/Loading';
/* Styles */
import { stylesGroup } from '../assets/css/ThemeCss';

export default function GroupScreen({navigation}) {
    const { user, logout } = useContext(AuthContext);
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        let isMounted = true;
        const unsubscribe = firebase.firestore().collection('Rooms')
          .onSnapshot((querySnapshot) => {
            const threads = querySnapshot.docs.map((documentSnapshot) => {
              return {
                _id: documentSnapshot.id,
                Name: documentSnapshot.Name,
                Subname: documentSnapshot.Subname,
                image: documentSnapshot.image,
                nMessages: documentSnapshot.nMessages,
                nMembers: documentSnapshot.nMembers,
                lastMessage: documentSnapshot.lastMessage,
                ...documentSnapshot.data(),
              };
            });

            console.log(threads);
    
            setThreads(threads);
    
            if (loading) {
              setLoading(false);
            }
          });
    
        /**
         * unsubscribe listener
         */
        return () => unsubscribe();
      }, []);
    
      if (loading) {
        return <Loading />;
    }

    function getHourTimestamp(datePost) {
      let dateTMP = new Date(datePost.toMillis()).toLocaleTimeString();
      let timeTMP = dateTMP.substring(0,5);
      return timeTMP;
    }

    return (
        <View style={stylesGroup.container}>
        <Header
            containerStyle={ stylesGroup.containerHeader }
            centerComponent={{ text: 'Agenda', style: stylesGroup.headerCenter  }}
            rightComponent={{/* icon: 'add', color: '#005354', size: 36, style: {paddingTop: 15}, onPress: () => navigation.navigate('AddRoom')*/}}
        />
        <FlatList
          style={stylesGroup.flatlist}
          data={threads}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <TouchableHighlight
              onPress={() => {
                navigation.navigate('RoomScreen', { thread: item, roomId: item._id })
              }}
            >
              <View style = {stylesGroup.listItemContainer}>
                <View style= {stylesGroup.iconContainer}>
                  <Image resizeMode="contain" source={ {uri: item.image }} style={stylesGroup.placeImage}/>
                </View>
                <View style = {stylesGroup.callerDetailsContainer}>
                  <View style={stylesGroup.callerDetailsContainerWrap}>
                    <View style={stylesGroup.nameContainer}>
                      <Text style={stylesGroup.nameList}>{item.Name}</Text>
                      <Text style={stylesGroup.subnameList}>{item.Subname}</Text>
                      <Text style={stylesGroup.nMessages}><Icon name={'message'} color='#00A68F' size='7'/>  {item.nMessages} | <Icon name={'group'} color='#00A68F' size='9'/>  {item.nMembers}</Text>
                    </View>
                    <View style={stylesGroup.callIconContainer}>
                      <Text style={{ fontWeight:'400', color:'#00A68F', fontSize:12 }}>{getHourTimestamp(item.lastMessage)}</Text>
                    </View>
                  </View>
                </View>
              </View>
          </TouchableHighlight>
          )}
      />
      </View>
    );
}