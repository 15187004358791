import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Image, Linking } from 'react-native';
import { Icon } from 'react-native-elements'
import { stylesRoom, stylesProfile, stylesLogin } from '../assets/css/ThemeCss';
import { AuthContext } from '../navigation/AuthProvider';
import { TouchableHighlight } from 'react-native-gesture-handler';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';

export default function ContactInformation({navigation}) {
    const { user, logout } = useContext(AuthContext);

    let userId = firebase.auth().currentUser.uid;    

    function renderCenterTitle(titleHeader) {
        return (
          <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
            <Text style={stylesRoom.headerCenter}>{titleHeader}</Text>
          </View>
        );
    }

    return (
        <View style={{flex: 1}}>
          <Header
            containerStyle={ stylesRoom.containerHeader }
            centerComponent={renderCenterTitle('Información de contacto')}
            leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, onPress: () => navigation.goBack()}}
            rightComponent={''}
          />
          <View>
            <View style={stylesLogin.container}>
                <Image style={{width: 350, height: 171, marginTop: 200 }}source={{uri: 'https://mediaticweb.com/cexp/logo.png'}} />
                <Text 
                    style={stylesProfile.paragraph}>
                        {'Caser Seguros'}
                </Text>
                <TouchableHighlight onPress={() => Linking.openURL('mailto:info@caser.es?subject=Contacto Appg&body=body')}>
                  <Text style={stylesProfile.paragraph}>{'info@caser.es'}</Text>
                </TouchableHighlight>
                <TouchableHighlight onPress={() => Linking.openURL('tel:900600100')}>
                  <Text style={stylesProfile.paragraph}>{'Teléfono: 900 600 100'}</Text>
                </TouchableHighlight>
                <Text style={{marginTop: 288}}>Copyright © 2021</Text>
            </View>
          </View>
        </View>
    );
  }