import React, { useContext, useEffect, useState } from 'react';
import { View, Text, Image } from 'react-native';
import { ListItem, Icon } from 'react-native-elements'
import { stylesRoom, stylesProfile, stylesGroup } from '../assets/css/ThemeCss';
import Loading from '../components/Loading';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';

export default function Medication({route, navigation}) {
    const { user, logout } = useContext(AuthContext);
    const [threads, setThreads] = useState([]);
    const [loading, setLoading] = useState(true);

    let userId = firebase.auth().currentUser.uid;
    
    useEffect(() => {
        let isMounted = true;
        const unsubscribe = firebase.firestore()
        .collection(`Profiles/${userId}/MEDICATIONS/`)
        .orderBy('createdAt', 'desc')
        .onSnapshot((querySnapshot) => {
            const threads = querySnapshot.docs.map((documentSnapshot) => {
              return {
                _id: documentSnapshot.id,
                name: documentSnapshot.name,
                createdAt: documentSnapshot.createdAt,
                image: documentSnapshot.image,
                taken: documentSnapshot.taken,
                ...documentSnapshot.data(),
              };
            });
            
            console.log(threads);
            setThreads(threads);
    
            if (loading) {
              setLoading(false);
            }
          });
    
        return () => unsubscribe();
      }, []);
    

      if (loading) {
        return <Loading />;
      }


    function renderCenterTitle(titleHeader) {
        return (
          <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
            <Text style={stylesRoom.headerCenter}>{titleHeader}</Text>
          </View>
        );
    }

    function parseHour(fecha) {
        let dateTMP = new Date(fecha.toMillis()).toLocaleDateString();
                
        return dateTMP;
    }

    if(threads.length > 0) {
        return (
            <View style={{flex: 1}}>
                <Header
                    containerStyle={ stylesRoom.containerHeader }
                    centerComponent={renderCenterTitle('Mis tratamientos')}
                    leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, onPress: () => navigation.goBack()}}
                    rightComponent={{/* icon: 'add', color: '#ffffff', size: 36, onPress: () => navigation.navigate('AddMedication')*/}}
                />
                <View style={{borderBottomWidth: 1, borderBottomColor: '#e2e2e2'}}>
                    <View style={stylesProfile.bodyHistorial}>
                        <Text 
                            style={stylesProfile.paragraph}>
                                {'Esta es la lista de los medicamentos que está tomando actualmente. Sólo su médico puede añadir o modificar los tratamientos. Cualquier aclaración consulte con su médico'}
                        </Text>
                    </View>
                </View>
                <View>
                {
                    threads.map((item, i) => (
                    <ListItem key={i} bottomDivider>
                        <Image resizeMode="contain" source={ {uri: item.image }} style={stylesGroup.placeImageBorder}/>
                        <ListItem.Content>
                        <ListItem.Title style={stylesProfile.titleMed}>{item.name}</ListItem.Title>
                        <ListItem.Subtitle style={stylesProfile.subtitleMed}>{parseHour(item.createdAt)}</ListItem.Subtitle>
                        <Text style={stylesProfile.takenMed}>{item.taken}</Text>
                        </ListItem.Content>
                    </ListItem>
                    ))
                }
                </View>
            </View>
        );

    } else {
        return (
            <View style={{flex: 1}}>
              <Header
                containerStyle={ stylesRoom.containerHeader }
                centerComponent={renderCenterTitle('Mis tratamientos')}
                leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, onPress: () => navigation.goBack()}}
                rightComponent={''}
              />
              <View>
                <View style={stylesProfile.bodyHistorial}>
                    <Text 
                        style={stylesProfile.paragraph}>
                            {'Esta es la lista de los medicamentos que está tomando actualmente. Sólo su médico puede añadir o modificar los tratamientos. Cualquier aclaración consulte con su médico'}
                    </Text>
                </View>
              </View>
              <Text>No hay tratamientos</Text>
            </View>
        );
    }
  }
