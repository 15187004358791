import React, { useState } from 'react';
import { View, StyleSheet } from 'react-native';
import { IconButton, Title } from 'react-native-paper';
import FormInput from '../components/FormInput';
import FormButton from '../components/FormButton';
import { firebase } from '../firebase/firebase';

export default function AddMedication({ navigation }) {
    const [medName, setmedName] = useState('');
    const [medDescription, setmedDescription] = useState('');
    const [medImage, setmedImage] = useState('');
    let userId = firebase.auth().currentUser.uid;

    function handleButtonPress() {
        if (medName.length > 0) {
            firebase.firestore().collection(`Profiles/${userId}/MEDICATIONS/`).add({name: medName, taken: medDescription, createdAt: new Date(), image: medImage}).then(()=>{
                navigation.goBack();
            });
        }
      }
  
    return (
      <View style={styles.rootContainer}>
        <View style={styles.closeButtonContainer}>
          <IconButton
            icon='close-circle'
            size={36}
            color='#005354'
            onPress={() => navigation.goBack()}
          />
        </View>
        <View style={styles.innerContainer}>
          <Title style={styles.title}>Añadir Tratamiento</Title>
          <FormInput
            labelName='Nombre'
            value={medName}
            onChangeText={(text) => setmedName(text)}
            clearButtonMode='while-editing'
          />
          <FormInput
            labelName='Descripción médica'
            value={medDescription}
            onChangeText={(text) => setmedDescription(text)}
            clearButtonMode='while-editing'
          />
          <FormInput
            labelName='Imagen'
            value={medImage}
            onChangeText={(text) => setmedImage(text)}
            clearButtonMode='while-editing'
          />
          <FormButton
            title='Añadir'
            modeValue='contained'
            labelStyle={styles.buttonLabel}
            onPress={() => handleButtonPress()}
            disabled={medName.length === 0}
          />
        </View>
      </View>
    );
  }


  const styles = StyleSheet.create({
    rootContainer: {
      flex: 1,
    },
    closeButtonContainer: {
      position: 'absolute',
      top: 30,
      right: 0,
      zIndex: 1,
    },
    innerContainer: {
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      fontSize: 24,
      marginBottom: 10,
    },
    buttonLabel: {
      fontSize: 22,
    },
  });