import React, { useEffect, useState } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import { IconButton, Title } from 'react-native-paper';
import FormInput from '../components/FormInput';
import { ListItem, Icon } from 'react-native-elements';
import FormButton from '../components/FormButton';
import { firebase } from '../firebase/firebase';

export default function AddMedication({ navigation }) {
    const [medName, setmedName] = useState('');
    const [medDescription, setmedDescription] = useState('');
    const [medImage, setmedImage] = useState('');
    const [allergies, setAllergies] = useState(null);
    const [searchText, setSearchText] = useState('');
    const [isSearching, setIsSearching] = useState(false);
    let userId = firebase.auth().currentUser.uid;
    const token = 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6MTA3MzEsImVtYWlsIjoiYXNzaXN0XzYyOUBjYXJlZXhwYW5kLmNvbSIsIm5hbWUiOiJDYXJlZXhwYW5kIFN1cHBvcnQiLCJ0aXRsZSI6bnVsbCwidG9rZW4iOiI3ZDVmZjAzNjc5ZmM1ODRlODM4ZTA4MGRiYTU4ODVmOGI4Mjc0YTU3NDNkZTQ5NGNjYyIsInRlbXBUb2tlbiI6IjdkNWZmMDM2NzlmYzU4NGU4MzhlMDgwZGJhNTg4NWY4YjgyNzRhNTc0M2RlNDk0Y2NjIiwidGllciI6InN0YWZmIiwidWNUaWVyIjoic3RhZmYiLCJyb2xlIjoiYXNzaXN0IiwiaXNDQ01QZXJzb25uZWwiOmZhbHNlLCJhdXRoQmlsbGluZyI6MCwiaWRHcm91cCI6NjI5LCJpZERlcGFydG1lbnQiOm51bGwsImlkUGFyZW50IjoxMDczMCwiZmlyc3RUaW1lIjpmYWxzZSwicHJvdmlkZXIiOm51bGwsInRpbWV6b25lIjpudWxsLCJpcCI6Ijo6MSIsImlzU3RhZmYiOnRydWUsImRiVGltZXpvbmUiOiJ7XCJvZmZzZXRcIjpcIi0wNTowMFwiLFwiem9uZVwiOlwiVVMvQ2VudHJhbFwifSIsImxhbmd1YWdlIjoiZW4tdXMiLCJ3YXNTdXBlckFkbWluIjpmYWxzZSwicGFyZW50TmFtZSI6ImQuIDIyIiwic3RhZmZMYWJlbCI6IihzdGFmZiBvZiBkLiAyMikiLCJsYXN0QWN0aW9uIjp7ImlkIjoiLTMiLCJ0aW1lIjoiMjAyMS0xMC0xMlQxNTo0OTo0N1oiLCJyZWZJZHMiOnt9fSwiZ2VvbG9jYXRpb24iOm51bGwsImlhdCI6MTYzNDA1Mzc4NywiZXhwIjoxNjM2NjQ1Nzg3fQ.NfAEuDgha2M4GNOAPw7VPXZPiWoZRvNLFFl21h8jWwgRLPfFjG5uqFv3-dHgKN68fK3jXk7pf_dyTSVCoMZS_w';
    const headers = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Authorization': token
    };

    async function searchAllergies() {
      try {
        const URL = `https://dev.careexpand.com/v2/search-metatags/?search=${searchText}&sections[]=Allergies`;
        setIsSearching(true);
        const response = await fetch(URL, {
          method: 'GET',
          headers: headers
        });
        const json = await response.json();
        setAllergies(json);
        setIsSearching(false);
      } catch (error) {
        setIsSearching(false);
        console.error(error);
      }
    }

    function renderSearchResults() {
      console.log(allergies);
      if(allergies && allergies.Allergies && allergies.Allergies.length > 0) {
        return allergies.Allergies.map((item, i) => (
          <ListItem key={i} bottomDivider onPress={() => handleButtonPress(item)}>
            <ListItem.Content>
              <Text>{item.code}</Text>
              <Text>{item.description}</Text>
            </ListItem.Content>
          </ListItem>
        ));
      }
      return (
        <View>
          <Text style={{textAlign: 'center'}}>No results found</Text>
        </View>
      )
    }

    function handleButtonPress(item) {
      firebase.firestore().collection(`Profiles/${userId}/ALLERGIES/`).add({...item, createdAt: new Date()}).then(()=>{
        navigation.goBack();
      });
    }
  
    return (
      <View style={styles.rootContainer}>
        <View style={styles.headingContainer}>
          <Title style={styles.title}>Add Allergies</Title>
          <IconButton
            icon='close-circle'
            size={24}
            color='#005354'
            onPress={() => navigation.goBack()}
          />
        </View>
        <FormInput
            labelName='Search Text'
            style={{width: '100%'}}
            value={searchText}
            onChangeText={(text) => {
              setSearchText(text);
              if(text.length>1) {
                searchAllergies();
              }
            }}
            clearButtonMode='while-editing'
          />
          {
            isSearching && (
              <View>
                <Text>Searching...</Text>
              </View>
            )
          }
        {renderSearchResults()}
      </View>
    );
  }


  const styles = StyleSheet.create({
    rootContainer: {
    },
    closeButtonContainer: {
      position: 'absolute',
      top: 30,
      right: 0,
      zIndex: 1,
    },
    innerContainer: {
      justifyContent: 'center',
    },
    headingContainer: {
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'space-between',
      padding: 5,
      borderBottomColor: 1,
      backgroundColor: '#f9f9f9',
      color: '#fff'
    },
    title: {
      fontSize: 24,
      margin: 5,
    },
    buttonLabel: {
      fontSize: 22,
    },
  });