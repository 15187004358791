import { createStackNavigator } from '@react-navigation/stack';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import React from 'react';
import Ionicons from 'react-native-vector-icons/Ionicons';
import { IconButton } from 'react-native-paper';

import HomeScreen from '../screens/HomeScreen';
import GroupScreen from '../screens/GroupScreen';
import NewsScreen from '../screens/NewsScreen';
import ProfileScreen from '../screens/ProfileScreen';
import AddRoomScreen from '../screens/AddRoomScreen';
import PrivateRoomScreen from '../screens/PrivateRoomScreen';
import RoomScreen from '../screens/RoomScreen';
import UserProfile from '../screens/UserProfile';
import MedicalHistory from '../screens/MedicalHistoryScreen';
import AddAllergies from '../screens/AddAllergies';
import Allergies from '../screens/Allergies';
import Diseases from '../screens/Diseases';
import Medication from '../screens/Medication';
import AddMedication from '../screens/AddMedication';
import ContactInformation from '../screens/ContactInformation';

const Tabs = createBottomTabNavigator();
const RootStack = createStackNavigator();

function MainStackScreen ({navigation}) {
  return(
      <Tabs.Navigator
      screenOptions={{animationEnabled: true},({ route }) => ({
        tabBarIcon: ({ size, color }) => {
            let icon;
            // Set different 'icons' for each route
            if (route.name === 'Consultas') {
              icon = 'chatbubbles-outline', size = 36
            } else if (route.name === 'Agendas') {
              icon = 'calendar-outline', size = 36
            } else if (route.name === 'Noticias') {
              icon = 'newspaper-outline', size = 36
            } else if (route.name === 'Perfil') {
              icon = 'person-outline', size = 36
            }

            return <Ionicons name={icon} size={size} color={color} />
        }
      })}
      tabBarOptions={{
        activeTintColor: '#007268',
        inactiveTintColor: '#FA7622',
        style:{
          height: 86,
          paddingBottom: 25,
          paddingTop: 4
        },
        labelStyle: {
          fontSize: 13,
          fontFamily: 'Dosis_500Medium',
          color: '#005354'
        },
      }}>
      <Tabs.Screen name='Consultas' component={HomeScreen}/>
      <Tabs.Screen 
        name='Agendas' 
        component={GroupScreen}
        options={({ navigation }) => ({
          headerRight: () => (
              <IconButton
              icon='chat-plus'
              size={28}
              color='#ff7701'
              onPress={() => navigation.navigate('AddRoom')}
          />)
        })}/>
      <Tabs.Screen name='Noticias' component={NewsScreen}/>
      <Tabs.Screen name='Perfil' component={ProfileScreen}/>
    </Tabs.Navigator>
  )}

export default function HomeStack() {
  return (
    <RootStack.Navigator mode='modal' headerMode='none'>
      <RootStack.Screen name='Index' component={MainStackScreen} />
      <RootStack.Screen name='AddRoom' component={AddRoomScreen} />
      <RootStack.Screen name='RoomScreen' component={RoomScreen} />
      <RootStack.Screen name='PrivateRoomScreen' component={PrivateRoomScreen} />
      <RootStack.Screen name='UserProfile' component={UserProfile} />
      <RootStack.Screen name='ContactInformation' component={ContactInformation} />
      <RootStack.Screen name='MedicalHistory' component={MedicalHistory} />
      <RootStack.Screen name='AddAllergies' component={AddAllergies} />
      <RootStack.Screen name='Allergies' component={Allergies} />
      <RootStack.Screen name='Diseases' component={Diseases} />
      <RootStack.Screen name='Medication' component={Medication} />
      <RootStack.Screen name='AddMedication' component={AddMedication} />
    </RootStack.Navigator>
  );
}