import React, { useContext, useState }from 'react';
import { View,Text, Image, Button } from 'react-native';
import { stylesProfile } from '../assets/css/ThemeCss';
import { ListItem, Icon } from 'react-native-elements'
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';


export default function ProfileScreen({navigation, route}) {
  const { logout } = useContext(AuthContext);
  const user = firebase.auth().currentUser;
  const [profile, setProfile] = useState('');

  //getUserAvatar
  firebase.firestore().collection('Profiles').doc(firebase.auth().currentUser.uid)
  .get().then(snapshot => setProfile(snapshot.data()));
  let avatar = profile.avatar;

  return (
      <View style={stylesProfile.container}>
          <View style={stylesProfile.header}></View>
          <Image style={stylesProfile.avatar} source={{uri: avatar}}/>
          <View style={stylesProfile.body}>
            <View style={stylesProfile.bodyContent}>
              <Text style={stylesProfile.name}>{user.displayName}</Text>
              <Text style={stylesProfile.description}>{user.email}</Text>              
            </View>
          <ListItem 
            bottomDivider 
            button onPress={() => {navigation.navigate('MedicalHistory')}} 
            containerStyle={stylesProfile.premium}>
            <Icon name={'star'} color='#fff'/>
            <ListItem.Content style={{flex: 2}}>
              <ListItem.Title style={stylesProfile.titlePremium}>{'Activar Premium'}</ListItem.Title>
              <Text style={stylesProfile.textPremium}>por 4,99€ al mes</Text>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => {navigation.navigate('UserProfile')}}>
            <Icon name={'edit'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Editar Perfil'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => {navigation.navigate('MedicalHistory')}}>
            <Icon name={'description'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Historial Médico'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => {navigation.navigate('Configuration')}}>
            <Icon name={'settings'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Configuración'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => {navigation.navigate('ContactInformation')}}>
            <Icon name={'phone'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Información de contacto'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => {navigation.navigate('PromotionalCode')}}>
            <Icon name={'subtitles'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Código Promocional'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
          <ListItem bottomDivider button onPress={() => logout()}>
            <Icon name={'logout'} color='#FA7622'/>
            <ListItem.Content>
              <ListItem.Title style={stylesProfile.title}>{'Cerrar Sesión'}</ListItem.Title>
            </ListItem.Content>
            <ListItem.Chevron />
          </ListItem>
        </View>
      </View>
  )
}