import React from 'react';
import { ActivityIndicator, View } from 'react-native';
import { stylesLoading } from '../assets/css/ThemeCss';

export default function Loading() {
  return (
      <View style={stylesLoading.loadingContainer}>
        <ActivityIndicator size="large" color="#317E7F" />
      </View>
  );
}