import React from 'react';
import Providers from './src/navigation/IndexProvider';
import AppLoading from 'expo-app-loading';
import { useFonts, Dosis_500Medium, Dosis_400Regular, Dosis_600SemiBold } from '@expo-google-fonts/dosis';

export default function App() {

  let [fontsLoaded] = useFonts({
    Dosis_400Regular,
    Dosis_500Medium,
    Dosis_600SemiBold
  });

  if (!fontsLoaded) {
    return <AppLoading />;
  }

  return <Providers />;
}
