import React, { useState, useContext } from 'react';
import { View, Image } from 'react-native';
import { stylesLogin } from '../assets/css/ThemeCss';
import { AuthContext } from '../navigation/AuthProvider';

import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import Loading from '../components/Loading';

export default function LoginScreen({ navigation }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, loading } = useContext(AuthContext);
  
  if (loading) {
    return <Loading />;
  }

  return (
      <View style={stylesLogin.container}>
        <Image style={{width: 400, height: 171 }}source={{uri: 'https://mediaticweb.com/cexp/logo.png'}} />
        <FormInput
            labelName="Email"
            value={email}
            autoCapitalize="none"
            onChangeText={(userEmail) => setEmail(userEmail)}
        />
        <FormInput
            labelName="Contraseña"
            value={password}
            secureTextEntry={true}
            onChangeText={(userPassword) => setPassword(userPassword)}
        />
        <FormButton
            title="Acceder"
            modeValue="contained"
            labelStyle={stylesLogin.loginButtonLabel}
            onPress={() => login(email, password)}
        />
        <FormButton
            title="Registrate aquí"
            modeValue="text"
            uppercase={false}
            labelStyle={stylesLogin.navButtonText}
            onPress={() => navigation.navigate("Signup")}
        />
      </View>
  );
}