import React, { useContext, useEffect, useState } from 'react';
import { GiftedChat, Bubble, Send, Composer } from 'react-native-gifted-chat';
import { ActivityIndicator, View, Image, Text, Platform, TouchableOpacity } from 'react-native';
import { IconButton } from 'react-native-paper';
import { stylesLoading, stylesRoom } from '../assets/css/ThemeCss';
import Loading from '../components/Loading';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';
import * as ImagePicker from 'expo-image-picker';
import { Camera } from 'expo-camera';


export default function RoomScreen({route, navigation}) {
    const { user, loading } = useContext(AuthContext);
    const [image, setImage] = useState(null);
    const roomId = route.params.roomId;
    const room = route.params.thread;
    const userId = firebase.auth().currentUser.uid;
    //const [type, setType] = useState(Camera.Constants.Type.back);
    //const [initCamera, setInitCamera] = useState(false);
    const [profile, setProfile] = useState('');
    const [messages, setMessages] = useState([
      {
        _id: 0,
        text: 'thread created',
        createdAt: new Date().getTime(),
        system: true
      }
    ]);

    if (loading) {
      setLoading(true)
    }

    //getUserAvatar
    firebase.firestore().collection('Profiles').doc(userId)
    .get().then(snapshot => setProfile(snapshot.data()));
    let avatar = profile.avatar;
    let userName = profile.name;

    useEffect(() => {
      let isMounted = true;
      const unsubscribe = firebase.firestore()
      .collection('Rooms')
      .doc(roomId)
      .collection('MESSAGES')
      .orderBy('createdAt', 'desc')
      .onSnapshot(querySnapshot => {
        const messages = querySnapshot.docs.map(documentSnapshot => {
          return {
            _id: documentSnapshot.id,
            created: documentSnapshot.createdAt,
            text: documentSnapshot.text,
            displayName: documentSnapshot.data().user.displayName,
            avatar:documentSnapshot.data().user.avatar,
            latestMessage: { text: '' },
            ...documentSnapshot.data()
          }
        })

        setMessages(messages)

        //Camera & Gallery permissions
        CameraLibraryPermissions()        

        if (loading) {
          setLoading(false)
        }
      })

      return () => unsubscribe()
    }, [])
  
    if (loading) {
      return <Loading />;
    }

    async function CameraLibraryPermissions() {
      if (Platform.OS !== 'web') {
        const { statusCamera } = await Camera.requestPermissionsAsync();
        if (statusCamera !== 'granted') {
          alert('Upss, necesitamos permisos de acceso a la camara para tener toda la funcionalidad disponible!');
        }
        const { statusLibrary } = await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (statusLibrary !== 'granted') {
          alert('Upss, necesitamos permisos de acceso a la galeria de fotos para tener toda la funcionalidad disponible!');
        }
      }
    }
  
    async function handleSend(newMessage = [], messages) {
      //setMessages(GiftedChat.append(messages, newMessage));
      let isMounted = true;

      if(newMessage[0].text != "") {
        firebase.firestore().collection('Rooms').doc(roomId).collection('MESSAGES').add({
          text: newMessage[0].text,
          type: 'text', 
          createdAt: new Date().getTime(),
          user: {
            _id: firebase.auth().currentUser.uid,
            displayName: userName,
            username: userName,
            avatar: avatar
          }
        });
  
        //Update unread messages
        updateUnreads();
        
      }
      
      /*********************************************************
       * MERGE MESSAGES
       * *******************************************************
      await firebase.firestore().collection('Rooms').doc(roomId).firebase.firestore().collection('Rooms').doc(roomId).set({
        latestMessage: {
          text: newMessage[0].text,
          createdAt: new Date().getTime()
        }
      },
      { merge: true })
      */
    }

    async function sendMessageImage(url) {
      firebase.firestore().collection('Rooms').doc(roomId).collection('MESSAGES').add({
        image: url,
        type: 'image',
        createdAt: new Date().getTime(),
        user: {
          _id: firebase.auth().currentUser.uid,
          displayName: userName,
          username: userName,
          avatar: avatar
        }
      });

      updateUnreads();
    }

    function updateUnreads() {
      let unread = room.nMessages + 1;
      firebase.firestore().collection('Rooms').doc(roomId).update({
        nMessages: unread,
        lastMessage: new Date()
      });
    }

    function renderBubble(props) {
      return (
        <Bubble
          {...props}
          wrapperStyle={{
            left: {
              backgroundColor: '#005354',
              padding: 10
            },
            right: {
              backgroundColor: '#ffffff',
              borderColor: '#FA7622',
              borderWidth: 2,
              padding: 10
            }
          }}
          textStyle={{
            right: {
              fontSize: 14,
              color: '#707070',
              fontFamily: 'Dosis_600SemiBold'
            }, 
            left: {
              fontSize: 14,
              color: '#fff',
              fontFamily: 'Dosis_600SemiBold'
            }
          }}
          timeTextStyle={{
            left: {
              color: '#fff',
            },
            right: {
              color: '#00A68F',
            },
          }}
        />
      );
  }

    function scrollToBottomComponent() {
        return (
          <View style={stylesRoom.bottomComponentContainer}>
            <IconButton icon='chevron-double-down' size={36} color='#6646ee' />
          </View>
        );
    }

    function renderLoading(props) {
        return (
          <View style={stylesLoading.loadingContainer}>
            <ActivityIndicator size='large' color='#6646ee' />
          </View>
        );
    }
    

    function mapUser(user) {
        return {
          _id: user.uid,
          username: user.displayName,
          avatar: avatar
        };
    }

    function renderImageHeader() {
      return (
        <Image resizeMode="contain" source={ {uri: room.image }} style={stylesRoom.placeImage}/>
      )
    }

    function renderCenterTitle(props) {
      return (
        <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
          <Text style={stylesRoom.headerCenter}>{room.Name}</Text>
          <Text style={stylesRoom.subHeaderCenter}>{room.Subname}</Text>
        </View>
      );
    }

    function renderSend(props){
      return (
        <Send {...props}>
          <View style={stylesRoom.sendingContainer}>
            <IconButton icon="send-circle" size={32} style={{paddingTop: 18}} color="#005354" onPress={ () => props.onSend({text: props.text}, true)}/>
          </View>
        </Send>
      );
    }

    function renderComposer(props) {
      return (
        <View style={stylesRoom.sendingContainer}>
          <IconButton icon="attachment" size={32} style={{marginTop: 8, width: 32}} color="#005354" onPress={pickImage}/>
          <IconButton icon="camera" size={32} style={{marginTop: 8, width: 32}} color="#005354" onPress={pickCamera}/>
          <Composer {...props}/>
            {/*image && <Image source={{ uri: image }} style={{ width: 40, height: 40 }} />*/}
        </View>
      );
    }

    const pickImage = async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      }).catch(err => {
        console.log(err)
      });
  
      if (!result.cancelled) {
        setImage(result.uri);
        console.log(result);
        //upload file to firebase storage and send message
        let uri = result.uri;
        let filename = userId + uri.substring(uri.lastIndexOf('/') + 1);
        uploadImage(uri, filename);
      }
    };

    const pickCamera = async () => {
      let result = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        aspect: [4, 3],
        base64: true,
      }).catch(err => {
        console.log(err)
      });

      if (!result.cancelled) {
        setImage(result.uri);
        //upload file to firebase storage and send message
        let uri = result.uri;
        let filename = userId + uri.substring(uri.lastIndexOf('/') + 1);
        console.log(filename);
        uploadImage(uri, filename);
      }
    }

    async function uploadImage(uri, name) {
      const response = await fetch(uri);
      const blob = response.blob();

      const ref = firebase.storage().ref().child(`images/${name}`);
      await ref.put(blob);
      await ref.getDownloadURL().then((url) => sendMessageImage(url));
      return true
    }
  
    return (
      <View style={{flex: 1}}>
        <Header
          containerStyle={ stylesRoom.containerHeader }
          centerComponent={renderCenterTitle()}
          leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, style: {paddingTop: 15}, onPress: () => navigation.goBack()}}
          rightComponent={renderImageHeader()}
        />
        <GiftedChat
          messages={messages}
          onSend={newMessage => handleSend(newMessage)}
          user={mapUser(firebase.auth().currentUser)}
          //renderUsernameOnMessage={true}
          renderBubble={renderBubble}
          renderLoading={renderLoading}
          placeholder='Escribe tu mensaje aquí...'
          alwaysShowSend
          renderComposer={renderComposer}
          renderSend={renderSend}
          showUserAvatar={true}
          //showAvatarForEveryMessage={false}
          alwaysRenderAvatar={true}
          infiniteScroll
          //loadEarlier={true}
          isAnimated
        />
      </View>
    );
  }
