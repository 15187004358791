import React, { useContext }from 'react';
import { View, Text, ImageBackground } from 'react-native';
import { stylesProfile, stylesRoom } from '../assets/css/ThemeCss';
import { ListItem, Icon } from 'react-native-elements'
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';


export default function MedicalHistoryScreen({navigation}) {
  const { logout } = useContext(AuthContext);
  const user = firebase.auth().currentUser;
  

  function renderCenterTitle(titleHeader) {
    return (
      <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
        <Text style={stylesRoom.headerCenter}>{titleHeader}</Text>
      </View>
    );
  }

  return (
      <View style={stylesProfile.container}>
          <Header
          containerStyle={ stylesRoom.containerHeader }
          centerComponent={renderCenterTitle('Mi Historial médico')}
          leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, onPress: () => navigation.goBack()}}
          rightComponent={''}
        />
        <View>
            <ImageBackground
                resizeMode={'cover'} // or cover
                style={{flex: 1}} // must be passed from the parent, the number may vary depending upon your screen size
                source={{uri:'http://mediaticweb.com/cexp/bghistorial.png'}}
            >
            <View style={stylesProfile.bodyHistorial}>
                <Text 
                    style={stylesProfile.paragraph}>
                        {'Para añadir información a tu historial médico tienes la ayuda de profesionales a tu servicio. Puedes compartir tu historial con ellos.'}
                </Text>
            </View>
            </ImageBackground>
        </View>
        <View>
            <ListItem bottomDivider topDivider button onPress={() => {navigation.navigate('Allergies')}}>
                <Icon name={'coronavirus'} color='#FA7622'/>
                <ListItem.Content>
                    <ListItem.Title style={stylesProfile.title}>{'Alergias'}</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
            </ListItem>
            <ListItem bottomDivider button onPress={() => {navigation.navigate('Diseases')}}>
                <Icon name={'sick'} color='#FA7622'/>
                <ListItem.Content>
                    <ListItem.Title style={stylesProfile.title}>{'Enfermedades'}</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
            </ListItem>
            <ListItem bottomDivider button onPress={() => {navigation.navigate('Medication')}}>
                <Icon name={'sanitizer'} color='#FA7622'/>
                <ListItem.Content>
                    <ListItem.Title style={stylesProfile.title}>{'Tratamientos'}</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
            </ListItem>
            <ListItem bottomDivider button onPress={() => {navigation.navigate('MedicalInformation')}}>
                <Icon name={'help'} color='#FA7622'/>
                <ListItem.Content>
                    <ListItem.Title style={stylesProfile.title}>{'Informe médico'}</ListItem.Title>
                </ListItem.Content>
                <ListItem.Chevron />
            </ListItem>
        </View>
    </View>
  )
}