import React, { useContext, useEffect, useState } from 'react';
import { GiftedChat, Bubble, Send, Composer } from 'react-native-gifted-chat';
import { IconButton } from 'react-native-paper';
import { StyleSheet, ActivityIndicator, View, Image, Text } from 'react-native';
import { stylesRoom } from '../assets/css/ThemeCss';
import Loading from '../components/Loading';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';
import * as ImagePicker from 'expo-image-picker';
import { Camera } from 'expo-camera';

export default function PrivateRoomScreen({route, navigation}) {
    const { user, loading } = useContext(AuthContext);
    const roomId = route.params.roomId;
    const room = route.params.thread;
    const userId = firebase.auth().currentUser.uid;
    const [profile, setProfile] = useState('');
    const [image, setImage] = useState(null);
    const [messages, setMessages] = useState([
      {
        _id: 0,
        text: 'thread created',
        createdAt: new Date().getTime(),
        system: true
      }
    ]);

    if (loading) {
      setLoading(true)
    }

    //getUserAvatar
    firebase.firestore().collection('Profiles').doc(userId)
    .get().then(snapshot => setProfile(snapshot.data()));
    let avatar = profile.avatar;
    let usernameProfile = profile.name;
    let role = profile.role;

    useEffect(() => {
      let isMounted = true;
      if( role == 'doctor') {
        const unsubscribeDoctor = firebase.firestore()
        .collection(`Doctors/${userId}/MEMBERS/${roomId}/MESSAGES`)
        .orderBy('createdAt', 'desc')
        .onSnapshot(querySnapshot => {
          const threads = querySnapshot.docs.map(documentSnapshot => {
            return {
              _id: documentSnapshot.id,
              text: documentSnapshot.text,
              avatar: documentSnapshot.avatar,
              read: documentSnapshot.read,
              ...documentSnapshot.data()
            }
          })
  
          setMessages(threads)
  
          if (loading) {
            setLoading(false)
          }
        })
  
        return () => unsubscribeDoctor()

      } else {
        const unsubscribe = firebase.firestore()
        .collection(`Doctors/${roomId}/MEMBERS/${userId}/MESSAGES`)
        .orderBy('createdAt', 'desc')
        .onSnapshot(querySnapshot => {
          const threads = querySnapshot.docs.map(documentSnapshot => {
            return {
              _id: documentSnapshot.id,
              text: documentSnapshot.text,
              avatar: documentSnapshot.avatar,
              read: documentSnapshot.read,
              ...documentSnapshot.data()
            }
          })
  
          setMessages(threads)
  
          if (loading) {
            setLoading(false)
          }
        })
  
        return () => unsubscribe()
      }
      
    }, [role, usernameProfile])
  
    if (loading) {
      return <Loading />;
    }

  
    // helper method that is sends a message
    async function handleSend(newMessage = [], messages) {
      //setMessages(GiftedChat.append(messages, newMessage));

      if(newMessage[0].text != "") {
        if(role == 'doctor') {
          firebase.firestore().collection(`Doctors/${userId}/MEMBERS/${roomId}/MESSAGES`).add({
            text: newMessage[0].text,
            type: 'text',
            createdAt: new Date().getTime(),
            user: {
              _id: firebase.auth().currentUser.uid,
              displayName: usernameProfile,
              avatar: avatar
            }
          });
          
        } else {
           //update member data
          firebase.firestore().collection(`Doctors/${roomId}/MEMBERS`).doc(userId).set({
            name: usernameProfile,
            avatar: avatar
          });
  
          firebase.firestore().collection(`Doctors/${roomId}/MEMBERS/${userId}/MESSAGES`).add({
            text: newMessage[0].text,
            type: 'text',
            createdAt: new Date().getTime(),
            user: {
              _id: firebase.auth().currentUser.uid,
              displayName: usernameProfile,
              avatar: avatar
            }
          });
        }
      }      

      /*********************************************************
       * MERGE MESSAGES
       * *******************************************************
        await firebase.firestore().collection('Doctors').doc(roomId).set({
          latestMessage: {
            text: newMessage[0].text,
            createdAt: new Date().getTime()
          }
        },
        { merge: true })
      */  
    }

    async function sendMessageImage(url) {
      if(role == 'doctor') {
        firebase.firestore().collection(`Doctors/${userId}/MEMBERS/${roomId}/MESSAGES`).add({
          image: url,
          type: 'image',
          createdAt: new Date().getTime(),
          user: {
            _id: firebase.auth().currentUser.uid,
            displayName: usernameProfile,
            avatar: avatar
          }
        });

      } else {
        firebase.firestore().collection(`Doctors/${roomId}/MEMBERS`).doc(userId).set({
          name: usernameProfile,
          avatar: avatar
        });

        firebase.firestore().collection(`Doctors/${roomId}/MEMBERS/${userId}/MESSAGES`).add({
          image: url,
          type: 'image',
          createdAt: new Date().getTime(),
          user: {
            _id: firebase.auth().currentUser.uid,
            displayName: usernameProfile,
            avatar: avatar
          }
        });
      }      

      //updateUnreads();
    }

    function renderBubble(props) {
        return (
          <Bubble
            {...props}
            wrapperStyle={{
              left: {
                backgroundColor: '#005354',
                padding: 10
              },
              right: {
                backgroundColor: '#ffffff',
                borderColor: '#FA7622',
                borderWidth: 2,
                padding: 10
              }
            }}
            textStyle={{
              right: {
                fontFamily: 'Dosis_600SemiBold',
                color: '#707070',
                fontSize: 14
              }, 
              left: {
                fontFamily: 'Dosis_600SemiBold',
                color: '#fff',
                fontSize: 14
              }
            }}
            timeTextStyle={{
              left: {
                color: '#fff',
              },
              right: {
                color: '#00A68F',
              },
            }}
          />
        );
    }

    function scrollToBottomComponent() {
        return (
          <View style={styles.bottomComponentContainer}>
            <IconButton icon='chevron-double-down' size={36} color='#6646ee' />
          </View>
        );
    }

    function renderLoading() {
        return (
          <View style={styles.loadingContainer}>
            <ActivityIndicator size='large' color='#6646ee' />
          </View>
        );
    }
    
    function mapUser(user) {
      return {
        _id: user.uid,
        name: user.displayName,
        avatar: avatar
      };
    }

    function renderImageHeader() {
      return (
        <Image resizeMode="contain" source={{uri: room.avatar }} style={stylesRoom.placeImage}/>
      )
    }

    function renderCenterTitle(propos) {
      return (
        <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
          <Text style={stylesRoom.headerCenter}>{room.name}</Text>
          <Text style={stylesRoom.subHeaderCenter}>{room.speciality}</Text>
        </View>
      );
    }

    function renderSend(props){
      return (
        <Send {...props}>
          <View style={stylesRoom.sendingContainer}>
            <IconButton icon="send-circle" size={32} style={{paddingTop: 18}} color="#005354" onPress={ () => props.onSend({text: props.text}, true)}/>
          </View>
        </Send>
      );
    }

    function renderComposer(props) {
      return (
        <View style={stylesRoom.sendingContainer}>
          <IconButton icon="attachment" size={32} style={{marginTop: 8, width: 32}} color="#005354" onPress={pickImage}/>
          <IconButton icon="camera" size={32} style={{marginTop: 8, width: 32}} color="#005354" onPress={pickCamera}/>
          <Composer {...props}/>
            {/*image && <Image source={{ uri: image }} style={{ width: 40, height: 40 }} />*/}
        </View>
      );
    }

    const pickImage = async () => {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.All,
        allowsEditing: true,
        aspect: [4, 3],
        quality: 1,
      }).catch(err => {
        console.log(err)
      });
  
      if (!result.cancelled) {
        setImage(result.uri);
        console.log(result);
        //upload file to firebase storage and send message
        let uri = result.uri;
        let filename = userId + uri.substring(uri.lastIndexOf('/') + 1);
        uploadImage(uri, filename);
      }
    };

    const pickCamera = async () => {
      let result = await ImagePicker.launchCameraAsync({
        allowsEditing: false,
        aspect: [4, 3],
        base64: true,
      }).catch(err => {
        console.log(err)
      });

      if (!result.cancelled) {
        console.log(result);
        setImage(result.uri);
        //upload file to firebase storage and send message
        let uri = result.uri;
        let filename = userId + uri.substring(uri.lastIndexOf('/') + 1);
        uploadImage(uri, filename);
      }
    }

    async function uploadImage(uri, name) {
      const response = await fetch(uri);
      const blob = response.blob();

      const ref = firebase.storage().ref().child(`images/${name}`);
      await ref.put(blob);
      await ref.getDownloadURL().then((url) => sendMessageImage(url));
      return true
    }
  
    return (
      <View style={{flex: 1}}>
        <Header
          containerStyle={ stylesRoom.containerHeader }
          centerComponent={renderCenterTitle()}
          leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, style: {paddingTop: 15}, onPress: () => navigation.goBack()}}
          rightComponent={renderImageHeader()}
        />
        <GiftedChat
          messages={messages}
          onSend={newMessage => handleSend(newMessage)}
          user={mapUser(firebase.auth().currentUser)}
          //renderUsernameOnMessage={true}
          renderBubble={renderBubble}
          renderLoading={renderLoading}
          placeholder='Escribe tu mensaje aquí...'
          alwaysShowSend
          renderComposer={renderComposer}
          renderSend={renderSend}
          showUserAvatar={true}
          //showAvatarForEveryMessage={false}
          alwaysRenderAvatar={true}
          infiniteScroll
          //loadEarlier={true}
          //isAnimated
        />
      </View>
    );
  }

  const styles = StyleSheet.create({

  });
