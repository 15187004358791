import { Dimensions, StyleSheet } from 'react-native';

const width = Dimensions.get('window').width;
const height = Dimensions.get('window').height;

const stylesInput = StyleSheet.create({
    input: {
      marginTop: 10,
      marginBottom: 10,
      width: width / 1.5,
      height: height / 15,
    },
});

const stylesButton = StyleSheet.create({
    button: {
      marginTop: 10,
      borderRadius: 50
    },
    buttonContainer: {
      width: width / 2,
      height: height / 15,
      borderRadius: 50
    },
});

const stylesLoading = StyleSheet.create({
    loadingContainer: {
      flex: 1,
      alignItems: 'center',
      justifyContent: 'center',
    },
});


/****************************************
 * STYLES LOGIN SCREEN
 ****************************************/
const stylesLogin = StyleSheet.create({
    container: {
      backgroundColor: '#ffff',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    titleText: {
      fontSize: 24,
      marginBottom: 10,
    },
    loginButtonLabel: {
      fontSize: 22,
    },
    navButtonText: {
      fontSize: 16,
    },
    navButton: {
      marginTop: 10,
    },
});

/****************************************
 * STYLES HOME SCREEN
 ****************************************/
const stylesHome = StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    flatlist: {
      flex: 1,
      width: '100%',
      paddingTop: 20
    },
    placeImage: {
      height: 72,
      width: 72,
      borderRadius: 50,
      borderWidth: 2,
      borderColor: '#dfdfdf',
      paddingLeft: 8
    },
    nameList: {
      paddingTop: 5,
      fontSize: 20,
      color: '#005354',
      fontFamily: 'Dosis_500Medium'
    },
    subnameList: {
      fontSize: 14,
      color: '#ff7701',
      fontFamily: 'Dosis_500Medium'
    },
    nMessages: {
      fontSize: 12,
      color: '#00A68F',
      paddingTop: 20,
      paddingBottom: 5,
      paddingLeft: 15
    },
    divider: {
      backgroundColor: '#EEEDED',
      width: '80%',
      right: 0
    },
    containerHeader: {
        width: '100%',
        backgroundColor: '#f9f9f9',
        borderBottomWidth: '100%',
        borderBottomColor: '#EEEDED'
    },
    headerCenter: {
        position: 'relative',
        color: '#005354',
        fontSize: 22,
        fontFamily: 'Dosis_600SemiBold',
        paddingTop: 20,
        paddingBottom: 15
    },
    listItemContainer: {
      flex: 1,
      flexDirection: "row",
      alignItems: "center",
      padding: 10
    },
    iconContainer: {
      flex: 1,
      alignItems: "flex-start",
      paddingLeft: 10
    },
    callerDetailsContainer: {
      flex: 4,
      justifyContent: "center",
      borderBottomColor: "rgba(92,94,94,0.2)",
      borderBottomWidth: 0.25,
      paddingLeft: 15
    },
    callerDetailsContainerWrap: {
      flex: 1,
      alignItems: "center",
      flexDirection: "row"
    },
    nameContainer: {
      alignItems: "flex-start",
      flex: 1
    },
    callIconContainer: {
      flex: -1,
      alignItems: "flex-end",
    },
});


/****************************************
 * STYLES GROUPS SCREEN
 ****************************************/
const stylesGroup = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  flatlist: {
    flex: 1,
    width: '100%',
    paddingTop: 20
  },
  placeImage: {
    height: 72,
    width: 72,
    borderRadius: 50,
    paddingLeft: 8
  },
  placeImageBorder: {
    height: 72,
    width: 72,
    borderRadius: 50,
    paddingLeft: 8,
    borderWidth: 1,
    borderColor: '#FA7622'
  },
  nameList: {
    paddingTop: 5,
    fontSize: 20,
    color: '#005354',
    fontFamily: 'Dosis_500Medium'
  },
  subnameList: {
    fontSize: 14,
    color: '#ff7701',
    fontFamily: 'Dosis_500Medium'
  },
  nMessages: {
    fontSize: 12,
    color: '#00A68F',
    paddingTop: 20,
    paddingBottom: 5,
    paddingLeft: 15
  },
  divider: {
    backgroundColor: '#EEEDED',
    width: '80%',
    right: 0
  },
  containerHeader: {
      width: '100%',
      backgroundColor: '#f9f9f9',
      borderBottomWidth: '100%',
      borderBottomColor: '#EEEDED'
  },
  headerCenter: {
      position: 'relative',
      color: '#005354',
      fontSize: 22,
      fontFamily: 'Dosis_600SemiBold',
      paddingTop: 20,
      paddingBottom: 15
  },
  listItemContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
    padding: 10
  },
  iconContainer: {
    flex: 1,
    alignItems: "flex-start",
    paddingLeft: 10
  },
  callerDetailsContainer: {
    flex: 4,
    justifyContent: "center",
    borderBottomColor: "rgba(92,94,94,0.2)",
    borderBottomWidth: 0.25,
    paddingLeft: 15
  },
  callerDetailsContainerWrap: {
    flex: 1,
    alignItems: "center",
    flexDirection: "row"
  },
  nameContainer: {
    alignItems: "flex-start",
    flex: 1
  },
  callIconContainer: {
    flex: -1,
    alignItems: "flex-end",
  },

});

/****************************************
 * STYLES ROOM SCREENS (PUBLIC PRIVATE)
 ****************************************/
const stylesRoom = StyleSheet.create({
    container: {
      backgroundColor: '#fff',
      flex: 1,
      justifyContent: 'center',
      alignItems: 'center',
    },
    containerHeader: {
        width: '100%',
        backgroundColor: '#FA7622',
        borderBottomWidth: '100%',
        borderBottomColor: '#EEEDED'
      },
      headerCenter: {
        color: '#fff',
        fontSize: 22,
        fontFamily: 'Dosis_600SemiBold',
        paddingTop: 15,
        paddingBottom: 8
      },
      subHeaderCenter: {
        color: '#fff',
        fontFamily: 'Dosis_400Regular',
        fontSize: 16
      },
      placeImage: {
        backgroundColor: '#fff',
        height: 48,
        width: 48,
        borderRadius: 50,
        marginTop: 15
      },
      sendingContainer: {
        flexDirection: 'row',
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        height: 44,
      },
      bottomComponentContainer: {
        justifyContent: 'center',
        alignItems: 'center'
      },
      loadingContainer: {
          flex: 1,
          alignItems: 'center',
          justifyContent: 'center'
      },    
});

/****************************************
 * STYLES NEWS SCREEN
 ****************************************/
const stylesNews = StyleSheet.create({
  container: {
    backgroundColor: '#fff',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    paddingBottom: 15
  },
  containerHeader: {
    width: '100%',
    backgroundColor: '#f9f9f9',
    borderBottomWidth: '100%',
    borderBottomColor: '#EEEDED'
  },
  headerCenter: {
    position: 'relative',
    color: '#005354',
    fontSize: 22,
    fontFamily: 'Dosis_600SemiBold',
    paddingTop: 20,
    paddingBottom: 15
  },
  titleCard: {
    paddingTop: 5,
    fontSize: 18,
    fontFamily: 'Dosis_600SemiBold',
    justifyContent: 'left',
  },
  datePost: {
    textAlign: 'right',
    fontFamily: 'Dosis_600SemiBold',
  },
  textPost: {
    textAlign: 'justify',
    fontFamily: 'Dosis_400Regular',
  },
  imagePost: {
    padding: 0, 
    borderWidth: 1, 
    borderColor: '#000000',
  },
  separator: {
    paddingTop: 5,
  }
});

/****************************************
 * STYLES PROFILE SCREEN
 ****************************************/
const stylesProfile = StyleSheet.create({
    container: {
      flex: 1
    },
    header:{
      backgroundColor: "#005354",
      height:120,
    },
    avatar: {
      width: 130,
      height: 130,
      borderRadius: 63,
      borderWidth: 4,
      borderColor: "white",
      marginBottom:10,
      alignSelf:'center',
      position: 'absolute',
      marginTop:50
    },
    body:{
      marginTop:40,
    },
    bodyContent: {
      flex: 1,
      alignItems: 'center',
      padding:30,
    },
    name:{
      fontSize:28,
      color: "#696969",
      fontFamily: 'Dosis_600SemiBold'
    },
    title:{
      fontSize:20,
      color: "#005354",
      fontFamily: 'Dosis_400Regular'
    },
    titleMed:{
      fontSize:20,
      color: "#005354",
      fontFamily: 'Dosis_600SemiBold'
    },
    subtitleMed: {
      fontSize:16,
      color: "#FA7622",
      fontFamily: 'Dosis_600SemiBold'
    },
    takenMed: {
      fontSize:16,
      color: "#000000",
      fontFamily: 'Dosis_600SemiBold'
    },
    info:{
      fontSize:16,
      color: "#00BFFF",
      marginTop:10
    },
    description:{
      fontSize:16,
      color: "#696969",
      marginTop:10,
      textAlign: 'center',
      fontFamily: 'Dosis_600SemiBold'
    },
    buttonContainer: {
      marginTop:10,
      height:45,
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom:20,
      width:250,
      borderRadius:30,
      backgroundColor: "#00BFFF",
    },
    containerOptions: {
      flex: 1,
      backgroundColor: '#fff',
    },
    premium: {
      backgroundColor: '#005354',
      color: '#fff'
    },
    titlePremium: {
      fontSize:23,
      color: "#fff",
      fontFamily: 'Dosis_600SemiBold'
    },
    textPremium: {
      fontSize:21,
      color: "#fff",
      fontFamily: 'Dosis_600SemiBold'
    },
    bodyHistorial: {
      backgroundColor: '#fff',
      padding: 30,
      alignContent: 'center',
      textAlign: 'center',
    },
    paragraph: {
      fontSize:16,
      fontFamily: 'Dosis_600SemiBold',
      color: '#00A68F'
    }
  
  });

export {stylesInput, stylesButton, stylesLoading, stylesLogin, stylesHome, stylesRoom, stylesGroup, stylesProfile, stylesNews}