import React, { useContext, useState, useEffect } from 'react';
import { View, FlatList, Text } from 'react-native';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header, Card } from 'react-native-elements';
import Loading from '../components/Loading';
import { TouchableHighlight } from 'react-native-gesture-handler';
import { stylesNews, stylesGroup } from '../assets/css/ThemeCss';


export default function NewsScreen({route, navigation}) {
  const { user, logout } = useContext(AuthContext);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    let isMounted = true;
    const unsubscribe = firebase.firestore().collection('News')
      .onSnapshot((querySnapshot) => {
        const posts = querySnapshot.docs.map((documentSnapshot) => {
          return {
            _id: documentSnapshot.id,
            title: documentSnapshot.Title,
            text: documentSnapshot.Text,
            image: documentSnapshot.image,
            dateCreated: documentSnapshot.dateCreated,
            ...documentSnapshot.data(),
          };
        });

        setPosts(posts);

        if (loading) {
          setLoading(false);
        }
      });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <Loading />;
  }

  function parseDate(datePost) {
    let dateTMP = new Date(datePost.toMillis()).toLocaleDateString();    
   
    return dateTMP;
  }

  return (
    <View style={stylesNews.container}>
    <Header
        containerStyle={ stylesNews.containerHeader }
        centerComponent={{ text: 'Noticias', style: stylesNews.headerCenter  }}
        rightComponent={{ icon: 'add', color: '#005354', size: 36, style: {paddingTop: 15}, onPress: () => navigation.navigate('AddNews')}}
    />
    <FlatList
      style={stylesGroup.flatlist}
      data={posts}
      keyExtractor={item => item._id}
      ItemSeparatorComponent={() => {
        return (
          <View style={stylesNews.separator}/>
        )
      }}
      renderItem={({ item }) => (
        <TouchableHighlight
          onPress={() => {
            //navigation.goBack()
          }}
        >
          <View style = {stylesNews.listItemContainer}>
            <Card style={{margin: 0, padding: 0}}>
              <Card.Image style={stylesNews.imagePost} source={{ uri:item.image,}}/>
              <Card.Title style={stylesNews.titleCard}>{item.title}</Card.Title>
              <Text style={stylesNews.datePost}>[ {parseDate(item.dateCreated)} ]</Text>
              <Text style={stylesNews.textPost}>{item.text}</Text>
            </Card>
          </View>
      </TouchableHighlight>
      )}
  />
  </View>
  );
}