import { NavigationContainer } from '@react-navigation/native';
import React, { useState, useContext, useEffect } from 'react';
import AuthStack from './AuthStack';
import HomeStack from './HomeStack';
import { AuthContext } from './AuthProvider';
import { firebase } from '../firebase/firebase';
/* Components */
import Loading from '../components/Loading';

export default function Routes({navigation}) {
    const { user, setUser } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [initializing, setInitializing] = useState(true);

  // Handle user state changes
  function onAuthStateChanged(user) {
      setUser(user ? true : false);
      if (initializing) setInitializing(false);
      setLoading(false);
      
      if(user==false) {
          navigation.navigate('Login');
      }
  }

  useEffect(() => {
      let isMounted = true;
      const subscriber = firebase.auth().onAuthStateChanged(onAuthStateChanged);
      return subscriber; // unsubscribe on unmount
  }, []);

  if (loading) {
      return <Loading />;
  }

  return (
      <NavigationContainer>
        {user ? <HomeStack />: <AuthStack />}
      </NavigationContainer>
  );
}