import React, { useContext, useState } from 'react';
import { View, Image } from 'react-native';
import { stylesLogin } from '../assets/css/ThemeCss';
import { IconButton, RadioButton } from 'react-native-paper';

import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import Loading from '../components/Loading';
import { AuthContext } from '../navigation/AuthProvider';

export default function SignupScreen({ navigation }) {
  const [displayName, setDisplayName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { register, loading } = useContext(AuthContext);
  const [value, setValue] = React.useState('woman');


  if (loading) {
    return <Loading />;
  }

  return (
      <View style={stylesLogin.container}>
        <Image style={{width: 400, height: 171 }}source={{uri: 'https://mediaticweb.com/cexp/logo.png'}} />
        <FormInput
            labelName="Usuario"
            value={displayName}
            autoCapitalize="none"
            onChangeText={(userDisplayName) => setDisplayName(userDisplayName)}
        />
        <FormInput
            labelName="Email"
            value={email}
            autoCapitalize="none"
            onChangeText={(userEmail) => setEmail(userEmail)}
        />
        <FormInput
            labelName="Contraseña"
            value={password}
            secureTextEntry={true}
            onChangeText={(userPassword) => setPassword(userPassword)}
        />
        <RadioButton.Group onValueChange={value => setValue(value)} value={value}>
          <RadioButton.Item label="Mujer" value="woman" />
          <RadioButton.Item label="Hombre" value="man" />
        </RadioButton.Group>
        <FormButton
          title="Registrarme"
          modeValue="contained"
          labelStyle={stylesLogin.loginButtonLabel}
          onPress={() => register(displayName, email, password, value)}
        />
        <IconButton
            icon="keyboard-backspace"
            size={30}
            style={stylesLogin.navButton}
            color="#ff7701"
            onPress={() => navigation.goBack()}
        />
      </View>
  );
}