import React, { createContext, useState } from 'react';
import { firebase } from '../firebase/firebase';
import { navigation } from '../navigation/HomeStack';

export const AuthContext = createContext({});

export const AuthProvider = ({ children, navigation }) => {
  const [user, setUser] = useState(false);
  const [loading, setLoading] = useState(false);
  let photoURL = '';

  return (
      <AuthContext.Provider
          value={{
            user,
            setUser,
            loading,
            setLoading,
            login: async (email, password) => {
                setLoading(true);

                try {
                    await firebase.auth().signInWithEmailAndPassword(email, password);
                } catch (e) {
                    console.log(e);
                }

                setLoading(false);

            },
            register: async (displayName, email, password, value) => {
                setLoading(true);

                if(value == 'man') {
                    photoURL = 'http://mediaticweb.com/cexp/man.png';
                } else {
                    photoURL = 'http://mediaticweb.com/cexp/girl.png';
                }

                try {
                    await firebase.auth().createUserWithEmailAndPassword(email,password).then(() => {
                        let sId = firebase.auth().currentUser.uid;

                        //Create profile
                        firebase.firestore().collection('Profiles').doc(sId).set({
                            online: true,
                            avatar: photoURL,
                            gender: value,
                            role: 'patient',
                            userId: sId,
                        });

                        setUser(true);
                    });
                } catch (e) {
                    console.log(e);
                }
                setLoading(false);
            },
            updateProfile: async (displayName, userAvatar) => {
                setLoading(true);

                try {
                    let sId = firebase.auth().currentUser.uid;

                    firebase.firestore().collection('Profiles').doc(sId).update({
                        avatar: userAvatar,
                        name: displayName
                    });

                    navigation.navigate('MedicalHistoryScreen');
                } catch (e) {
                    console.error(e);
                }

                setLoading(false);
            },
            logout: async () => {
                try {
                    let sId = firebase.auth().currentUser.uid;
                    //updateStatusOnline
                    firebase.firestore().collection('Doctors').doc(sId).update({
                        online: false
                    });
                    firebase.firestore().collection('Profiles').doc(sId).update({
                        online: false
                    });

                    setUser(false);
                    await firebase.auth().signOut();
                } catch (e) {
                    console.error(e);
                }
            },
          }}>

        {children}
      </AuthContext.Provider>
  );
};