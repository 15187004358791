import firebase from 'firebase';
import '@firebase/auth';
import '@firebase/firestore';

const firebaseConfig = {
  /*
    apiKey: "AIzaSyAH4g9knMpeWapV_OkZsdmaHaSFSWyBMFg",
    authDomain: "cexp-chat-ce2d6.firebaseapp.com",
    projectId: "cexp-chat-ce2d6",
    storageBucket: "cexp-chat-ce2d6.appspot.com",
    messagingSenderId: "343993016652",
    appId: "1:343993016652:web:f1dea24561c404b31fafe9"
    */
    apiKey: "AIzaSyA1qvnQtsRu7SxTC4layRqLxF7Wgn46JaA",
    authDomain: "cexp-chat-d286c.firebaseapp.com",
    databaseURL: "https://cexp-chat-d286c-default-rtdb.firebaseio.com",
    projectId: "cexp-chat-d286c",
    storageBucket: "cexp-chat-d286c.appspot.com",
    messagingSenderId: "369969327124",
    appId: "1:369969327124:web:6290ee2d49515c695e4b01"
};

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

export { firebase };
export const auth = firebase.auth();
export const firestore = firebase.firestore();