import React, { useState, useContext, useEffect } from 'react';
import { View, FlatList, Image, Text } from 'react-native';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';
import { stylesHome } from '../assets/css/ThemeCss';

import Loading from '../components/Loading';
import { TouchableHighlight } from 'react-native-gesture-handler';

export default function HomeScreen({ navigation }) {
  const { user, logout } = useContext(AuthContext);
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(true);
  const [role, setUserRole] = useState('');
  const userId = firebase.auth().currentUser.uid;

  //getUserRole
  firebase.firestore().collection('Profiles').doc(userId).get().then(
    (doc) => setUserRole(doc.data().role)
  );

  //updateStatusOnline
  if(role == 'doctor') {
    firebase.firestore().collection('Doctors').doc(userId).update({
      online: true
    });
  }  
  firebase.firestore().collection('Profiles').doc(userId).update({
    online: true
  });

  useEffect(() => {
    let isMounted = true;
    if(role == 'doctor') {
      const unsubscribeDoctor = firebase.firestore().collection(`Doctors/${userId}/MEMBERS`)
      .onSnapshot((querySnapshot) => {
        const threads = querySnapshot.docs.map((documentSnapshot) => {
          return {
            _id: documentSnapshot.id,
            name: documentSnapshot.name,
            speciality: documentSnapshot.role,
            avatar: documentSnapshot.avatar,
            online: documentSnapshot.online,
            lastConnection: documentSnapshot.lastConnection,
            unread: documentSnapshot.unread,
            ...documentSnapshot.data(),
          };
        });

        setThreads(threads);

        if (loading) {
          setLoading(false);
        }
      });

      return () => unsubscribeDoctor();

    } else {

      const unsubscribe = firebase.firestore().collection('Doctors')
      .onSnapshot((querySnapshot) => {
        const threads = querySnapshot.docs.map((documentSnapshot) => {
          return {
            _id: documentSnapshot.id,
            name: documentSnapshot.name,
            speciality: documentSnapshot.speciality,
            avatar: documentSnapshot.avatar,
            online: documentSnapshot.online,
            unread: documentSnapshot.unread,
            ...documentSnapshot.data(),
          };
        });

        setThreads(threads);

        if (loading) {
          setLoading(false);
        }
      });

      return () => unsubscribe();
    }
  }, [role]);

  
  if (loading) {
    return <Loading />;
  }

  function parseHour(dateUser) {
    let dateTMP = new Date(dateUser.toMillis()).toLocaleTimeString();
    dateTMP = dateTMP.substr(0, 5);
    
    return dateTMP;
  }

  function getStatusUser(item) {
    if (item.online == true) {
      return(<Text style={{ fontFamily: 'Dosis_600SemiBold', color:'#00A68F', fontSize:12 }}>{'online'}</Text>);
    } else {
      return (<Text style={{ fontFamily: 'Dosis_600SemiBold', color:'#FF3333', fontSize:12 }}>{'offline'}</Text>);
    }
  }

  return (
    <View style={stylesHome.container}>
      <Header
          containerStyle={ stylesHome.containerHeader }
          centerComponent={{ text: 'Consultas', style: stylesHome.headerCenter  }}
      />
      <FlatList
          style={stylesHome.flatlist}
          data={threads}
          keyExtractor={item => item._id}
          renderItem={({ item }) => (
            <TouchableHighlight
              onPress={() => {
                navigation.navigate('PrivateRoomScreen', { thread: item, roomId: item._id, imageChannel: item.avatar, nameChannel: item.name, speciality: item.speciality })
              }}
            >
            <View style = {stylesHome.listItemContainer}>
              <View style= {stylesHome.iconContainer}>
                <Image resizeMode="contain" source={ {uri: item.avatar }} style={stylesHome.placeImage}/>
              </View>
            <View style = {stylesHome.callerDetailsContainer}>
             <View style={stylesHome.callerDetailsContainerWrap}>
              <View style={stylesHome.nameContainer}>
                <Text style={stylesHome.nameList}>{item.name}</Text>
                <Text style={stylesHome.subnameList}>{item.speciality}</Text>
                <Text style={stylesHome.nMessages}>{item.unread}</Text>
               </View>
              <View style={stylesHome.callIconContainer}>
                  {getStatusUser(item)}
              </View>
            </View>
           </View>
          </View>
          </TouchableHighlight>
          )}
      />
    </View>
  );
}
