import React, { useContext, useState, useEffect } from 'react';
import { View, Image, Text } from 'react-native';
import { stylesRoom, stylesLogin } from '../assets/css/ThemeCss';
import { AuthContext } from '../navigation/AuthProvider';
import { firebase } from '../firebase/firebase';
import { Header } from 'react-native-elements';
import FormButton from '../components/FormButton';
import FormInput from '../components/FormInput';
import Loading from '../components/Loading';
import ProfileScreen from './ProfileScreen';

export default function UserProfile({navigation}) {
    const [displayName, setDisplayName] = useState('');
    const [userAvatar, setUserAvatar] = useState('');
    const [password, setPassword] = useState('');
    const [profile, setProfile] = useState('');
    const { updateProfile, loading } = useContext(AuthContext);

    const userId = firebase.auth().currentUser.uid;
    
    //getUserProfile
    firebase.firestore().collection('Profiles').doc(userId)
    .get().then(snapshot => setProfile(snapshot.data()));

    let userName = profile.name;
    let avatar = profile.avatar;

    

    function renderCenterTitle(titleHeader) {
        return (
          <View style={{flex: 1,  justifyContent: 'center', alignItems: 'center'}}>
            <Text style={stylesRoom.headerCenter}>{titleHeader}</Text>
          </View>
        );
    }

    if (loading) {
        return <Loading />;
    }

    return (
      <View style={{flex: 1}}>
        <Header
          containerStyle={ stylesRoom.containerHeader }
          centerComponent={renderCenterTitle('Editar Perfil')}
          leftComponent={{ icon: 'chevron-left', color: '#fff', size: 48, onPress: () => navigation.goBack()}}
          rightComponent={''}
        />
        <View style={stylesLogin.container}>
            <Image style={{width: 130, height: 130, borderRadius: 63, borderWidth: 4, borderColor: "gray",  }}source={{uri: profile.avatar}} />
            <FormInput
                labelName="Usuario actual"
                value={userName}
                autoCapitalize="none"
                onChangeText={(displayName) => setDisplayName(displayName)}
            />
            <FormInput
                labelName="Usuario nuevo"
                value={displayName}
                autoCapitalize="none"
                onChangeText={(displayName) => setDisplayName(displayName)}
            />
            <FormInput
                labelName="Imagen actual"
                value={avatar}
                autoCapitalize="none"
                onChangeText={(userAvatar) => setUserAvatar(userAvatar)}
            />
            <FormInput
                labelName="Imagen nueva"
                value={userAvatar}
                autoCapitalize="none"
                onChangeText={(userAvatar) => setUserAvatar(userAvatar)}
            />
            <FormButton
            title="Actualizar"
            modeValue="contained"
            labelStyle={stylesLogin.loginButtonLabel}
            onPress={() => updateProfile(displayName, userAvatar)}
            />
        </View>
      </View>
    );
  }