import React from 'react';
import { stylesButton } from '../assets/css/ThemeCss';
import { Button } from 'react-native-paper';

export default function FormButton({ title, modeValue, ...rest }) {
    return (
        <Button
            mode={modeValue}
            {...rest}
            style={stylesButton.button}
            contentStyle={stylesButton.buttonContainer}
        >
          {title}
        </Button>
    );
  }
  