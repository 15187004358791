import React from 'react';
import { stylesInput } from '../assets/css/ThemeCss';
import { TextInput } from 'react-native-paper';

export default function FormInput({ labelName, ...rest }) {
  return (
      <TextInput
          label={labelName}
          style={stylesInput.input}
          numberOfLines={1}
          {...rest}
      />
  );
}

